/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const carouselProviderSX: SxProps<Theme> = (theme) => ({
	maxWidth: '1440px',
	margin: '0 auto',
	position: 'relative',
	py: 0,
	px: 8,
	[theme.breakpoints.down(480)]: {
		px: 0,
	},
	'&.carousel': {
		[theme.breakpoints.down(415)]: {
			padding: '0 16px 0 16px',
		},
	},
	[theme.breakpoints.down(410)]: {
		'.carousel__slider.carousel__slider--horizontal': {
			height: 'inherit',
			'> .carousel__slider-tray-wrapper.carousel__slider-tray-wrap--horizontal': {
				height: 'inherit',
				'> .carousel__slider-tray.carousel__slider-tray--horizontal': {
					height: 'inherit',
					'> .carousel__slide': {
						height: 'inherit',
					},
				},
			},
		},
	},

	'.carousel__back-button, .carousel__next-button': {
		position: 'absolute',
		top: '35%',
		transform: 'translateY(-50%)',
		height: (theme) => theme.spacing(5),
		borderRadius: '50%',
		background: 'none',
		border: '1px solid transparent',
		color: '#656373',
	},

	'.carousel__back-button': {
		left: 0,
		'& svg': {
			color: '#656D73',
			fontSize: 'xx-large',
			'&:hover': {
				color: '#a3aaaf',
			},
		},
	},

	'.carousel__next-button': {
		right: 0,
		'& svg': {
			fontSize: 'xx-large',
			color: '#656D73',
			'&:hover': {
				color: '#a3aaaf',
			},
		},
	},
	'.carousel__dot': {
		height: '16px',
		width: '16px',
		margin: '8px',
		borderRadius: '50%',
		border: '2px solid rgb(240, 240, 240)',
		'&:hover': {
			background: '#a3aaaf',
		},
	},

	'button.carousel__dot--selected': {
		background: '#752A43',
		border: '2px solid rgba(255, 255, 255, 0.5)',
		'&:hover': {
			background: '#752A43',
		},
	},

	'.MuiRating-icon.MuiRating-iconEmpty': {
		color: '#EFAE50 !important',
	},

	'.MuiTypography-body1': {
		fontSize: 14,
	},

	'.MuiTypography-body2': {
		fontSize: 14,
	},

	div: {
		outline: 'none',
	},
});

export const dotContainerSX: SxProps<Theme> = (theme) => ({
	paddingTop: '32px',
	paddingBottom: '48px',
	[theme.breakpoints.down('md')]: {
		paddingTop: '32px',
		paddingBottom: '32px;',
	},
});
