
export const getAttributeValue = (attributeValue: string, identifierArray: any) => {
    const getSelectedIdentifier = identifierArray?.filter((item: {identifier: string})=> item.identifier == attributeValue )
    if(getSelectedIdentifier?.length>0){
      return getSelectedIdentifier[0]?.values?.[0]?.value;
    }else{
      return '';
    }
  }

  export const getValueByKey = (key: string, returnKey: string, id: string, data: any) => {
    const foundObject = data?.find((obj: { [x: string]: string; }) => obj?.[id] === key);
		return foundObject ? foundObject?.[returnKey] : 1;
  }
