/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ButtonBack, ButtonNext, Dot, DotGroup, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useTheme } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { CarouselProvider } from '@/components/blocks/Carousel/CarouselProvider';
import { CarouselSlide } from '@/components/blocks/Carousel/CarouselSlide';
import { carouselProviderSX, dotContainerSX } from '@/components/blocks/FeaturedProductCarousel/styles/carouselProvider';
import { carouselSlideSX } from '@/components/blocks/FeaturedProductCarousel/styles/carouselSlide';
import { CarouselOptions } from '@/data/types/Carousel';
import { Grid, SxProps, Theme, useMediaQuery } from '@mui/material';
import { AriaAttributes, useMemo } from 'react';

type CarouselSliderProps = {
	a11yProps?: AriaAttributes[];
	slides: JSX.Element[];
	carouselProps?: CarouselOptions;
	carouselSlideStyles?: SxProps<Theme>;
};

const defaultCarouselProps = {
	naturalSlideWidth: 248,
	naturalSlideHeight: 300,
	visibleSlides: 1,
	step: 1,
	infinite: true,
	dragEnabled: false,
	totalSlides: 1,
	isIntrinsicHeight: true,
};

const EMPTY_A11_PROPS: AriaAttributes[] = [];
export const FeaturedProductCarousel = ({
	a11yProps = EMPTY_A11_PROPS,
	slides,
	carouselProps,
	carouselSlideStyles = carouselSlideSX,
}: CarouselSliderProps) => {
	const theme = useTheme();
	const lgMatch = useMediaQuery(theme.breakpoints.down('lg'));
	const mdMatch = useMediaQuery(theme.breakpoints.down('md'));
	const smMatch = useMediaQuery(theme.breakpoints.down('sm'));
	const visibleSlides = useMemo(
		() => (smMatch ? 2 : mdMatch ? 3 : lgMatch ? 5 : 5),
		[lgMatch, mdMatch, smMatch]
	);
	const mergedProps = { ...defaultCarouselProps, ...carouselProps };

	return (
		<CarouselProvider {...mergedProps} totalSlides={slides.length} sx={carouselProviderSX}>
			<Slider >
				{slides.map((slide: JSX.Element, i: number) => (

					<CarouselSlide
						index={i}
						key={slide.key}
						sx={carouselSlideStyles}
						{...(a11yProps[i] ?? {})}
					>

						{slide}

					</CarouselSlide>

				))}
				{/* {slides.map((index)=>{return <Dot slide={index.toString()} key={index}/>})} */}
			</Slider>
			{!smMatch ? <> <ButtonBack>
				<ArrowBackIosRoundedIcon />
			</ButtonBack>
				<ButtonNext>
					<ArrowForwardIosRoundedIcon />
				</ButtonNext> </> : null}
			{/* {slides.map((s,index)=>{return <Dot slide={index} key={index}/>})} */}
			<Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={dotContainerSX}>
				{slides.map((s, index) => index % visibleSlides === 0 ? <Dot slide={index} key={index} style={{ padding: '8px' }} /> : null)}
			</Grid>
		</CarouselProvider>
	);
};
