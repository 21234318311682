/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2024.
 */

export const LOCATION = 'online';
export const DESC_NAME = 'Save for later list';
export const DESC = 'List of saved items';
export const GIFT_CARD = 'false';
export const ACCESS = 'Private';
export const SAVE_FOR_LATER_STATE = 'SaveForLater';
export const ONE_QUANTITY = '1.0';
